<script lang="ts" setup>

definePageMeta({
  name: 'index',
})

useSeoMeta({
  title: 'Vragen van vandaag',
  description: "Alle vragen die vandaag aan het Onderwijsloket zijn gesteld en worden beantwoord door onze adviseurs",
})

const { fetchInterval, date } = useSettings()

const { data, error, refresh, status } = useAsyncData('questions_'+ Date.now(), async () => {
  return await $fetch('/api/day', {
    query: {
      day: !!date.value ? new Date(date.value).toISOString() : new Date().toISOString()
    }
  })
}, {
  server: false,
  watch: [date]
})

const isLoading = computed(() => {
  return status.value === 'pending'
})
useIntervalFn(() => {
  refresh()
}, fetchInterval)


//  Override the date that might be cached since yesterday
onNuxtReady(() => {
  date.value = new Date()
})

</script>


<template>
  <NuxtLayout name="site">
    <div>
        <div class="relative w-full h-[calc(100dvh-60px)] sm:h-[calc(100dvh-108px)] sm:min-h-[600px]">
          <MetaQuestions
            :questions="data"
            :is-loading="isLoading"
          />
          <ClientOnly>
            <LoadingState v-if="isLoading && !data?.length" />
            <div v-else-if="!data?.length" class="h-full w-full grid place-items-center">
              <div class="flex flex-col justify-center items-center h-full my-4">
                <nuxt-img
                  src="v1690356360/onderwijsloket/dev/empty_pctjzu.png"
                  width="180px"
                  height="auto"
                  sizes="xs:180px"
                  class="dark:opacity-60 transition-opacity object-contains w-[40%] sm:w-1/4"
                />
                <div class="dark:text-gray-400 text-gray-700 font-bold text-lg mt-4 text-center max-w-full md:w-[32rem]">
                  <p>Er zijn vandaag nog geen vragen gesteld aan onze adviseurs...Ben jij de eerste vraagsteller van de dag? bel snel met <NuxtLink to="tel:0302270374" target="_blank" class="no-underline"><GradientText>030 - 227 03 74</GradientText></NuxtLink></p>
                </div>
              </div>
            </div>
            <Slider 
              v-else="!!data"
              :questions="data"
              :is-loading="isLoading"
            />

            <template #fallback>
              <LoadingState />
            </template>
          </ClientOnly>          
        </div>
    </div>

    <Settings class="mt-20" />
    <Banner class="mt-12" color="primary" :list="[{label: 'een onafhankelijk advies', icon: 'i-heroicons-light-bulb-16-solid'}, { label: 'persoonlijk en op maat', icon: 'i-heroicons-user-circle-16-solid'}, { label: 'op een moment dat jou schikt', icon: 'i-heroicons-calendar-date-range-16-solid'}]">
      <template #heading>
        Een persoonlijk advies over je mogelijkheden in het onderwijs?
      </template>
      <template #subheading>
        De adviseurs van het Onderwijsloket helpen je verder.
      </template>
      <template #buttons>
        <SavvyCalEmbed />
        <UButton variant="soft" color="white" to="https://www.onderwijsloket.com/plan-een-adviesgesprek/" target="_blank">meer informatie</UButton>
      </template>
    </Banner>
    
  </NuxtLayout>
</template>