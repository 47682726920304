<template>
  <SliderContainer>
    <SlideContainer>
      <USkeleton class="sm:hidden max-w-4xl w-full h-6 md:h-8 rounded-full mb-4 sm:mb-6" />
      <USkeleton class="sm:hidden max-w-4xl w-[95%] h-6 md:h-8 rounded-full mb-4 sm:mb-6" />
      <USkeleton class="block max-w-4xl w-full h-6 md:h-8 rounded-full mb-4 sm:mb-6" />
      <USkeleton class="block max-w-3xl w-[80%] h-6 md:h-8 rounded-full" />


      <div class="absolute bottom-4 left-0 right-0 flex justify-between gap-8 items-end sm:items-center">
        <div class="flex items-center justify-center space-x-4">
          <USkeleton class="rounded-full h-14 w-14" />
          <div class="mt-1">
            <USkeleton class="rounded-full w-32 h-3 mb-3" />
            <USkeleton class="rounded-full w-40 h-4" />
          </div>
        </div>

        <USkeleton class="w-12 sm:w-48 rounded-md h-6" />
      </div>
    </SlideContainer>
  </SliderContainer>
</template>