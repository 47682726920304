import { isSameDay } from 'date-fns';


export const useSettings = () => {

  const defaultValues = {
    fetch: 10000,
    slide: 12000,
    date: new Date()
  }

  const fetchInterval = useState('fetch_interval', () => defaultValues.fetch)
  const slideInterval = useState('slide_interval', () => defaultValues.slide)
  const date = useState('date', () => defaultValues.date)


  const reset = () => {
    fetchInterval.value = defaultValues.fetch
    slideInterval.value = defaultValues.slide
    date.value = defaultValues.date
  }

  const isDefaultValue = computed(() => fetchInterval.value === defaultValues.fetch && slideInterval.value === defaultValues.slide && isSameDay(date.value, defaultValues.date))
  
  return {
    fetchInterval,
    slideInterval,
    date,

    reset,
    isDefaultValue
  }
}
