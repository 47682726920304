<template>
  <div>
    <UAlert 
      title="Pas de instellingen van de presentatie aan" 
      icon="i-heroicons-adjustments-vertical-20-solid" 
      color="info" 
      variant="soft"
      :actions="[
        {
          label: 'open instellingen',
          color: 'info',
          variant: 'solid',
          click: () => showSettings = true
        }
      ]"
    />
    <USlideover v-model="showSettings">
      <UCard
        :ui="{
          divide: 'divide-y divide-gray-200 dark:divide-gray-700',
          background: 'dark:bg-gray-900',
          header: {
            padding: 'py-4'
          },
          footer: {
            padding: 'py-6'
          },
          shadow: 'shadow-none',
          rounded: 'rounded-none',
          ring: 'ring-0',
          base: 'min-h-full',
          body: { base: 'overflow-y-scroll'}
        }"
      >
        <template #header>
          <div class="flex justify-between">
            <h5 class="mb-0">Slider instellingen</h5>
          </div>
        </template>

        <div class="flex flex-col gap-8">
          <UFormGroup label="Data" hint="Hoe vaak moet de vragen ververst worden?">
            <URange :min="5000" :max="50000" v-model="fetchInterval" />
            <p class="text-xs text-gray-500 mt-2 ">om de {{ msToSeconds(fetchInterval)}} seconden</p>
          </UFormGroup>

          <UFormGroup label="Slides" hint="Hoe snel moeten de slides rouleren?">
            <URange :min="1000" :max="50000" v-model="slideInterval" />
            <p class="text-xs text-gray-500 mt-2 ">om de {{ msToSeconds(slideInterval)}} seconden</p>
          </UFormGroup>
          <UFormGroup label="Datum" hint="Van welke dag wil je vragen tonen">
              <div class="w-full date-picker">

              </div>
              <DatePicker v-model="date" :columns="1" />
          </UFormGroup>

          
        </div>
      
        

        <template #footer>
          <div class="flex justify-end">
            <UButtonGroup size="lg">
              <UButton v-if="!isDefaultValue" color="gray" variant="soft" label="Reset" :ui="{variant: { soft: 'dark:hover:bg-gray-800'}}" @click="reset" />
              <UButton color="emerald" variant="soft"  label="Oke" @click="close" />
            </UButtonGroup>
          </div>
          
        </template>
      </UCard>
    </USlideover>
  </div>
  
</template>

<script lang="ts" setup>


const {
    fetchInterval,
    slideInterval,
    date,

    reset,
    isDefaultValue
} = useSettings()

const showSettings = ref(false)

const close = () => {
  showSettings.value = false
}


function msToSeconds(ms: number): number {
  return parseFloat((ms / 1000).toFixed(2));
}

</script>

<style>
.date-picker > div {
  width: 100%;
}
</style>