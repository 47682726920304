<template>
  <div class="absolute left-0 top-4 right-20 w-full flex items-center gap-8 justify-between">
      <div class="flex items-center gap-4">
        <h4 class="mb-0">
          Er {{ questions?.length === 1 ? 'is' : 'zijn' }} vandaag <GradientText>{{ questions?.length ||0 }} {{ questions?.length === 1 ? 'vraag' : 'vragen' }}</GradientText> gesteld
        </h4>
        <ClientOnly>
          <Transition name="loading">
            <UIcon v-if="loading" name="i-heroicons-arrow-path-20-solid" class="animate-spin w-6 h-6 text-gray-500" />
          </Transition>
        </ClientOnly>
        
      </div>
      <div v-if="!!adviseurs.length" class="hidden sm:flex items-center gap-4">
        <h5 class="mb-0 text-gray-500 dark:text-gray-300">adviseurs van vandaag</h5>
        <UAvatarGroup>
          <UAvatar 
            v-for="adviseur in adviseurs"
            :key="adviseur.user_id"
            :src="img(adviseur.avatar_public_id, { width: 40, height: 40, c: 'fill', g: 'face' })"
          />
        </UAvatarGroup>
      </div>
    </div>
</template>

<script lang="ts" setup>
import type { Question } from '~~/types';

const props = defineProps<{
  questions?: Question[],
  isLoading: boolean,
}>()

const adviseurs = computed(() => {
  if (!props.questions) return []
  return Array.from(
    new Map(
      props.questions
        .map(q => q.createdBy)
        .filter(person => person !== null && person !== undefined)
        .map(person => [person.user_id, person])
    ).values()
  );
});


const loading = ref(false)

watch(() => props.isLoading, (newVal) => {
  if(newVal) loading.value = true
  else setTimeout(() => loading.value = false, 500)
}, {
  immediate: true
})

const img = useImage()


</script>
<style>

.loading-enter-active {
  transition: opacity 0.5s ease-in-out;
}
.loading-leave-active {
  transition: opacity 0.5s ease-in-out 0.5s;
}
.loading-enter-from,
.loading-leave-to {
  opacity: 0;
}
.loading-enter-to,
.loading-leave-from {
  opacity: 1;
}
</style>