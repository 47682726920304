<template>
    <SliderContainer>
      <Transition 
        name="slide-fade" 
        mode="out-in"
      >
        <SlideContainer
          v-if="currentQuestion"
          :key="currentQuestion.id"
        >

          <h1 class="question max-w-4xl w-full text-4xl md:text-6xl font-bold text-center leading-tight">
            {{ currentQuestion.value }}
          </h1>

          <div class="absolute bottom-4 left-0 right-0 flex justify-between gap-8 items-end sm:items-center">
              <div class="author flex items-center justify-center space-x-4">
                <NuxtImg
                  :src="currentQuestion.createdBy.avatar_public_id"
                  :modifiers="{ g: 'face' }"
                  fit="fill"
                  width="80"
                  height="80"
                  :alt="currentQuestion.createdBy.name"
                  class="rounded-full h-14 w-14"
                />
                <div class="text-gray-600 mt-2">
                  <p class="text-2xs font-bold dark:text-white">Deze vraag is beantwoord door</p>
                  <p class="font-bold mb-0"><GradientText>{{ currentQuestion.createdBy.name }}</GradientText></p>
                </div>
              </div>

              <div class="date">
                <UBadge color="gray" variant="soft" size="sm">
                  <span class="hidden sm:inline mr-1">Gesteld op {{ getDate(currentQuestion) }} om</span> {{ getTime(currentQuestion) }}
                </UBadge>
              </div>
          </div>
        </SlideContainer>
      </Transition>
    </SliderContainer>
</template>

<script setup lang="ts">
import type { Question } from '~~/types';

const props = defineProps<{
  questions?: Question[],
}>()

const currentIndex = ref(0)

const {
    slideInterval,
} = useSettings()

const currentQuestion = computed(() => props.questions?.[currentIndex.value] || null)

const getNextQuestion = () => {
  if (!props.questions) currentIndex.value = 0
  else if (currentIndex.value === props.questions.length - 1) currentIndex.value = 0
  else currentIndex.value++
}

useIntervalFn(() => getNextQuestion(), slideInterval)

const getDate = (q: Question): string => {
  const date = new Date(q.createdAt);
  
  const months = [
    'januari', 'februari', 'maart', 'april', 'mei', 'juni', 
    'juli', 'augustus', 'september', 'oktober', 'november', 'december'
  ];
  
  const day = date.getDate();
  const month = months[date.getMonth()];
  
  return `${day} ${month}`;
};

const getTime = (q: Question): string => {
  const date = new Date(q.createdAt);
  
  const hours = date.getHours().toString().padStart(2, '0');
  const minutes = date.getMinutes().toString().padStart(2, '0');
  
  return `${hours}:${minutes}`;
};

</script>



<style>
/* Fade animation for author and date */
.fade-enter-active, .fade-leave-active {
  transition: opacity 0.5s ease-in-out;
}
.fade-enter-from, .fade-leave-to {
  opacity: 0;
}
.fade-enter-to, .fade-leave-from {
  opacity: 1;
}

/* Slide and fade animation for the question */
.slide-fade-enter-active {
  transition: transform 0.8s ease, opacity 0.8s ease;
}
.slide-fade-leave-active {
  transition: transform 0.8s ease, opacity 0.8s ease;
}
.slide-fade-enter-from {
  opacity: 0;
}
.slide-fade-enter-to {
  opacity: 1;
}
.slide-fade-leave-from {
  opacity: 1;
}
.slide-fade-leave-to {
  opacity: 0;
}

</style>